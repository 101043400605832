import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";
import React from "react";
// import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../components/layout";
// import Container from "../components/container";
// import SEO from "../components/seo";
//import { toPlainText } from "../utils";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      title
      slug {
        current
      }
      mainImage {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
      }
    }
  }
`;

const BlogPostTemplate = ({ data, errors, location }) => {
  const post = data && data.post;
  return (
    <Layout location={location}>
      {/* {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )} */}

      {/* {errors && <GraphQLErrorList errors={errors} />} */}

      {post && <BlogPost {...post} />}
    </Layout>
  );
};

export default BlogPostTemplate;
