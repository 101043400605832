import React from "react";
import Image from "gatsby-plugin-sanity-image";

export const Figure = ({ image }) => {
  console.log(image);
  if (!image || !image.asset || !image.asset._id) {
    return null;
  }

  return (
    <figure>
      <Image {...image} alt={image.asset.altText} />
      <figcaption>{image.asset.caption}</figcaption>
    </figure>
  );
};
