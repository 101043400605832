import { PortableText as BasePortableText } from "@portabletext/react";
import React from "react";
import { Figure } from "./figure";
import VimeoVideo from "./vimeoVideo";
import SyntaxHighlighter from "react-syntax-highlighter";
import { shadesOfPurple } from "react-syntax-highlighter/dist/esm/styles/hljs";

const components = {
  types: {
    image: ({ value }) => {
      console.log("Image value", value);
      return <Figure image={value} />;
    },
    videoId: ({ value }) => {
      if (value.service === "vimeo") {
        return <VimeoVideo videoId={value.id} />;
      }
    },
    code: ({ value }) => {
      return (
        <SyntaxHighlighter language={value.language} style={shadesOfPurple}>
          {value.code}
        </SyntaxHighlighter>
      );
    },
  },
};

const PortableText = ({ blocks }) => (
  <BasePortableText value={blocks} components={components} />
);

export default PortableText;
