import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
//import { differenceInDays, formatDistance, format } from "date-fns";
// import Container from "./container";
import PortableText from "./portableText";
import kebabCase from "lodash/kebabCase";
//import { buildImageObj } from "../utils";
//import { imageUrlFor } from "../utils";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledPostContainer = styled.main`
  max-width: 1000px;
`;
const StyledPostHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledPostContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }
`;

function BlogPost(props) {
  const { _rawBody, categories, title, mainImage, publishedAt } = props;
  return (
    <StyledPostContainer>
      <span className="breadcrumb">
        <span className="arrow">&larr;</span>
        <Link to="/blog">All posts</Link>
      </span>
      <article>
        <StyledPostHeader>
          <h1 className="medium-heading">{title}</h1>
          <p className="subtitle">
            <time>
              {new Date(publishedAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </time>
            <br />
            {categories &&
              categories.length > 0 &&
              categories.map((category) => (
                <Link
                  key={category.id}
                  to={`/blog/tags/${kebabCase(category.title)}/`}
                  className="tag"
                >
                  #{category.title.replace(" ", "")}
                </Link>
              ))}
          </p>
        </StyledPostHeader>
        <StyledPostContent>
          {mainImage && mainImage.asset && (
            <div>
              <GatsbyImage
                alt={mainImage.alt}
                image={mainImage.asset.gatsbyImageData}
              />
            </div>
          )}
          {_rawBody && <PortableText blocks={_rawBody} />}
        </StyledPostContent>
      </article>
    </StyledPostContainer>
  );

  // return (
  //   <article className={styles.root}>
  //     {mainImage && mainImage.asset && (
  //       <div className={styles.mainImage}>
  //         <img
  //           src={imageUrlFor(buildImageObj(mainImage))
  //             .width(1200)
  //             .height(Math.floor((9 / 16) * 1200))
  //             .fit("crop")
  //             .auto("format")
  //             .url()}
  //           alt={mainImage.alt}
  //         />
  //       </div>
  //     )}
  //     {/* <Container> */}
  //     <div className={styles.grid}>
  //       <div className={styles.mainContent}>
  //         <h1 className={styles.title}>{title}</h1>
  //         {_rawBody && <PortableText blocks={_rawBody} />}
  //       </div>
  //       <aside className={styles.metaContent}>
  //         {publishedAt && (
  //           <div className={styles.publishedAt}>
  //             {differenceInDays(new Date(publishedAt), new Date()) > 3
  //               ? formatDistance(new Date(publishedAt), new Date())
  //               : format(new Date(publishedAt), "MMMM Mo, yyyy")}
  //           </div>
  //         )}
  //         {/* {authors && <AuthorList items={authors} title="Authors" />} */}
  //         {categories && (
  //           <div className={styles.categories}>
  //             <h3 className={styles.categoriesHeadline}>Categories</h3>
  //             <ul>
  //               {categories.map((category) => (
  //                 <li key={category._id}>{category.title}</li>
  //               ))}
  //             </ul>
  //           </div>
  //         )}
  //       </aside>
  //     </div>
  //     {/* </Container> */}
  //   </article>
  // );
}

export default BlogPost;
