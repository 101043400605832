import React from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
`;

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VimeoVideo = ({ videoId }) => {
  return (
    <VideoWrapper>
      <VideoFrame
        src={`https://player.vimeo.com/video/${videoId}`}
        frameborder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></VideoFrame>
    </VideoWrapper>
  );
};

export default VimeoVideo;
